export interface SharedState {
    titleDash: string;
    isDarkTheme: boolean;
		isLoading: boolean;
		isDrawer: boolean;
    isUserLogin: boolean;
}

function state(): SharedState {
    return {
        titleDash: "Principal",
        isDarkTheme: false,
				isLoading: false,
				isDrawer: true,
        isUserLogin: true
    }
}

export default state;