import { GetterTree } from 'vuex';
import { AuthState } from './state';
import { StateInterface } from '../index';


const getters: GetterTree<AuthState, StateInterface> = {
    currentState(state) {
        return state.status
    },

    currentUser(state) {
        return state.user || ''
    },
    getToken(state) {
        return state.idToken || 'unset'
    },
}



export default getters;