import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
//import es from "vuetify/lib/locale/es";
import colors from "vuetify/lib/util/colors";
import "@mdi/font/css/materialdesignicons.css";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: "#2F80ED",
        secondary: "#424242",
        accent: "#82B1FF",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
				warning: "#FFC107",
				menuColor: "#121826",
				menuSubColor: "1B243B",
				fontWhite: "#FFFFFF",
				bgWhite: "#FFFFFF",
				appBackground: "#F3F3F9",
        pmsDriver: "7D21F3",
				grey: colors.blueGrey.lighten1,
        greyDark: colors.blueGrey.darken2,
        primaryDark: colors.blue.darken4,
      },
      dark: {
        primary: colors.lightBlue.darken2,
        secondary: "#424242",
        accent: "#82B1FF",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
				warning: "#FFC107",
				appBackground: "#F3F3F9",
      },
    },
  },
  lang: {
    //locales: { es },
    current: "en",
  },
  icons: {
    iconfont: "mdi", // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
  }
  
});

