import {
  ResponseUser,
  ResponseActions,
  PassRecovery,
} from "@/interfaces/authInterfaces";
import {
  ResetPassword,
  UserRole,
  AdminResetPassword,
} from "@/interfaces/userInterfaces";

import { requestApi, rApiWHeader } from "@/services/shared/requestApi";
import { ResponseData } from "@/interfaces/shared/response";
const getUserLogin = async () => {
  return await requestApi("get", "/api/auth/User/GetUser");
};

const loginUser = async (data: any): Promise<ResponseUser> => {
  let resp = await requestApi("post", "/api/auth/Login/authenticate", data);
  return resp != undefined ? (resp as ResponseUser) : ({} as ResponseUser);
};

const getActionsUser = async (
  userName: string,
  token: string
): Promise<ResponseActions> => {
  let resp = await rApiWHeader(
    "get",
    "/api/auth/Authorization/Privileges?rolName=" +
      userName +
      "&systemCode=PMS&onlyAccess=true",
    token
  );
  return resp != undefined
    ? (resp as ResponseActions)
    : ({} as ResponseActions);
};

const sendForgotPassword = async (userName: string): Promise<ResponseData> => {
  let resp = await requestApi(
    "get",
    "/api/auth/Login/forgot-password?userName=" + userName
  );
  return resp != undefined ? (resp as ResponseData) : ({} as ResponseData);
};

const resetPassword = async (data: ResetPassword): Promise<ResponseData> => {
  let resp = await requestApi("post", "/api/auth/Login/reset-password", data);
  return resp != undefined ? (resp as ResponseData) : ({} as ResponseData);
};

const adminResetPassword = async (
  data: AdminResetPassword
): Promise<ResponseData> => {
  let resp = await requestApi(
    "post",
    "/api/auth/Login/adminResetPassword",
    data
  );
  return resp != undefined ? (resp as ResponseData) : ({} as ResponseData);
};
const adminResetPasswords = async (
  data: Array<AdminResetPassword>
): Promise<ResponseData> => {
  let resp = await requestApi(
    "post",
    "/api/auth/Login/adminResetPassword",
    data
  );
  return resp != undefined ? (resp as ResponseData) : ({} as ResponseData);
};

const postUserRole = async (data: any) => {
  return await requestApi("post", "/api/auth/Authorization/UserRole", data);
};

const removeUserRole = async (data: any) => {
  return await requestApi("delete", "/api/auth/Authorization/UserRole", data);
};
const getUserRole = async (data: any) => {
  return await requestApi(
    "post",
    "/api/auth/Authorization/UserRole/Search",
    data
  );
};

const getRolesSearch = async (data: any) => {
  return await requestApi("post", "/api/auth/Authorization/Role/Search", data);
};

const postRole = async (data: any) => {
  return await requestApi("post", "/api/auth/Authorization/Role", data);
};

const postRoles = async (data: any) => {
  return await requestApi("post", "/api/auth/Authorization/Roles", data);
};

const putRole = async (data: any) => {
  let response = await requestApi(
    "patch",
    "/api/auth/Authorization/Role",
    data
  );
  return response;
};

const getActionsSearch = async (data: any) => {
  return await requestApi(
    "post",
    "/api/auth/Authorization/Action/Search",
    data
  );
};

const postAction = async (data: any) => {
  return await requestApi("post", "/api/auth/Authorization/Action", data);
};

const putAction = async (data: any) => {
  return await requestApi("patch", "/api/auth/Authorization/Action", data);
};

const postRoleAction = async (data: any) => {
  return await requestApi("post", "/api/auth/Authorization/RoleAction", data);
};

const putRoleAction = async (data: any) => {
  return await requestApi("put", "/api/auth/Authorization/RoleAction", data);
};

const removeRoleAction = async (data: any) => {
  let response = await requestApi(
    "delete",
    "/api/auth/Authorization/RoleAction",
    data
  );
  return response;
};

const removeUserRoles = async (data: UserRole[]) => {
  let resp = await requestApi(
    "post",
    "/api/auth/Authorization/RemoveUserRoles",
    data
  );
  return resp;
};

const getPassToken = async (data: PassRecovery): Promise<ResponseData> => {
  let resp =
    (await requestApi("post", "/api/auth/Login/getPassToken", data)) ||
    ({} as ResponseData);
  return resp;
};

const unlockTokenUser = async (data: any): Promise<ResponseData> => {
  let resp =
    (await requestApi("post", "/api/auth/Login/unlockJwt", data)) ||
    ({} as ResponseData);
  return resp;
};

export {
  getUserLogin,
  loginUser,
  getActionsUser,
  postUserRole,
  removeUserRole,
  getUserRole,
  sendForgotPassword,
  resetPassword,
  getRolesSearch,
  postRole,
  putRole,
  getActionsSearch,
  postAction,
  putAction,
  postRoleAction,
  removeRoleAction,
  putRoleAction,
  postRoles,
  removeUserRoles,
  adminResetPassword,
  adminResetPasswords,
  getPassToken,
  unlockTokenUser
};
