
import Vue, {
	ref
} from "vue";

import {
	mapMutations
} from "vuex";
import store from "@/store";

import btnRounded from "@/components/shared/BtnRounded.vue";

import { loginUser, getActionsUser, sendForgotPassword } from '@/services/authServices/authServices';

import { LoginUserI, ResponseActions } from "@/interfaces/authInterfaces";
import { ResponseData } from '../../interfaces/shared/response';


export default Vue.extend({
	name: 'LoginView',
	components: {
		btnRounded,
	},
	data() {
		return {
			user: {} as LoginUserI,
			userReset: "",
			dgTitleSms: "",
			dialogMessageShow: false,
			dialogMessageText: "",
			showPassword: false,
			dgResetPassword: false,

		};
	},

	mounted() {

	},
	methods: {
		closeDelete() {
			this.$data.dialogMessageShow = false;
			this.$data.dialogMessageText = "";
			this.$data.dgTitleSms = "";
			
		},
		async login() {
			if (this.$data.user.userName != "" && this.$data.user.password != "") {
				this.user.userName = this.user.userName.trim();
				this.user.password = this.user.password.trim();
				var resp = await loginUser(this.$data.user);

				if (resp.succeeded) {
					var actionsU = await getActionsUser(resp.data.userName, resp.data.jwToken) as ResponseActions;
					if (actionsU.succeeded) {
						//this.authUser();
						await store.commit('Auth/authUser', { user: JSON.stringify(resp.data), actionsU: JSON.stringify(actionsU.data), idToken: resp.data.jwToken });


						if(resp.data.roles.some(x => x == "Passenger")){
							this.$router.push({
								name: 'formLocationServicesD2D'
							});
						} else {
							this.$router.push({
								name: 'home'
							});
						}
					} else {
						this.$data.dialogMessageShow = true

						this.$data.dgTitleSms = "Acceso Inválido";
			
						this.$data.dialogMessageText = "Por favor revisa que tu <b>usuario y contraseña</b> sean correctos e intenta nuevamente."; //resp.message;
					}
				} else {
					this.$data.dgTitleSms = "Acceso Inválido";
					this.$data.dialogMessageShow = true
					this.$data.dialogMessageText = "Por favor revisa que tu <b>usuario y contraseña</b> sean correctos e intenta nuevamente."; //resp.message;
				}
			} else {
				this.$data.dialogMessageShow = true
				this.$data.dgTitleSms = "Acceso Inválido";
				this.$data.dialogMessageText = "Ingrese un usuario y contraseña";
			}
		},
		async sendRecovery() {
			if (this.$data.userReset != undefined && this.$data.userReset.length > 1) {
				var resp = await sendForgotPassword(this.$data.userReset) as ResponseData;
				this.$data.dialogMessageShow = true
				this.$data.dialogMessageText = resp.message;

				this.$data.dgTitleSms = resp.succeeded ? "Correcto" : "Error";
				if(resp.succeeded){
					this.closeRecovery();
				}
			}else{
				this.$data.dialogMessageShow = true
				this.$data.dialogMessageText = "Ingrese un usuario";

				this.$data.dgTitleSms = "Error";
			}
		},
		closeRecovery() {
			this.$data.userReset = "";
			this.$data.dgResetPassword = false;
		},
		...mapMutations('Auth', ['authUser'])
	},
	computed: {

	},
});
