"use strict";

import Vue from "vue";
import axios from "axios";
import store from "@/store";
//console.log("Link: " + JSON.stringify(process.env))
// Full config:  https://github.com/axios/axios#request-config
//axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || 'https://stagingpanel.sistema506.com';


axios.defaults.baseURL = process.env.VUE_APP_ROOT_API || "https://api-pms.grupopurdy.com";

if (window.location.hostname == "staging-pms.grupopurdy.com") {
    axios.defaults.baseURL = "https://api-pms.purdymo.com";
    //console.log("OnIf");
}
//console.log(window.location.hostname);
//axios.defaults.baseURL = process.env.baseURL || "http://localhost:13123";

//axios.defaults.headers.common['Authorization'] = "bearer " + localStorage.getItem("idToken"); //+ localStorage.getItem("idToken");

axios.defaults.headers.common['Accept'] = "application/json";
axios.defaults.headers.common['Access-Control-Allow-Origin'] = "*";
axios.defaults.headers.common['Access-Control-Allow-Headers'] = "X-Requested-With"
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
axios.defaults.headers.common['key'] = 'AP1j&E!StNRq5DkdyEH7aJs2dOfLX';

let config = {
    //baseURL: process.env.baseURL || "http://localhost:13123",
    // baseURL: process.env.baseURL || process.env.apiUrl || 'https://stagingpanel.sistema506.com',
    //baseURL: process.env.VUE_APP_ROOT_API || "https://api-pms.grupopurdy.com",
    timeout: 60 * 1000, // Timeout
    withCredentials: true, // Check cross-site Access-Control
};

const _axios = axios.create(config);

_axios.interceptors.request.use(
    config => {
        //config.headers['Authorization'] = `Bearer ${localStorage.getItem('access_token')}`;
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

// Add a response interceptor
_axios.interceptors.response.use(
    function(response) {
        // Do something with response data
        return response;
    },
    function(error) {
        // Do something with response error
        return Promise.reject(error);
    }
);

Plugin.install = function(Vue, options) {
    Vue.axios = _axios;
    window.axios = _axios;
    Object.defineProperties(Vue.prototype, {
        axios: {
            get() {
                return _axios;
            },
        },
        $axios: {
            get() {
                return _axios;
            },
        },
    });
};

Vue.use(Plugin);

export default Plugin;