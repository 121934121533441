import { DataUser,ActionUser } from "@/interfaces/authInterfaces";

export interface AuthState {
  status: string;
  user: DataUser | null;
  idToken: string | null;
  actionsUser: ActionUser[] | null;
  //refreshToken: boolean | null;
}

function state(): AuthState {
  return {
    status: 'not-authenticated', // 'authenticated','not-authenticated', 'authenticating'
    user: null,
    idToken: null,
    actionsUser: null,
    //refreshToken: null
  }
}

export default state;