
import Vue from 'vue'

export default Vue.extend({
    name: 'btnRounded',
    props: {
        textBtn: String,
        colorBtn: String,
        iconT: {
            type: String,
            default: ""
        }, eventC: {
            type: Function,
            default: () => { }
        },
        classBtn: {
            type: String,
            default: ""
        }, 
        to:{
            type:Object
        },
        disable: {
            type:Boolean,
            default: false
        },
        styleBtn: {
            type: String,
            default: ""
        }, 
    },
    methods: {

    }
})
