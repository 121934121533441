
import Vue from "vue";

import MenuAppBar from "@/components/menuComponents/MenuAppBar.vue";
import Login from "@/views/authViews/LoginView.vue";
import {
    mapMutations
} from "vuex";
import { useAuth } from '@/composables';
import store from '@/store';


export default Vue.extend({
    setup() {

        const {
            authStatus,
            checkAuthStatus
        } = useAuth()


        const init = async () => {
            await checkAuthStatus()

        }

        init();

        return {
            authStatus
        }
    },
    name: "App",
    components: {
        MenuAppBar,
        Login
    },

    data() {
        return {
            // status: useAuth().authStatus
        }
    },

    computed: {
        theme() {
            return (this.$vuetify.theme.dark) ? 'dark' : 'light'
        },
        title() {
            return this.$store.state.Shared.titleDash;
        },
        isLoading() {
            return this.$store.state.Shared.isLoading;
        },
        isUserLogin() {
            return this.$store.state.Shared.isUserLogin
        },

    },
    methods: {
        setDrawerState() {
            this.stateDrawer(true)
        },
        setUserLogout() {
            this.userLogin(false)
            this.stateDrawer(false)


        },

        getUserLogin() {
            return (this.$store.state.Auth.idToken) ? true : false;
        },
        ...mapMutations('Shared', ['stateDrawer', 'userLogin'])
    },
    async beforeMount() {
        //console.log("check");
        const { ok } = await store.dispatch('Auth/checkAuthentication');
        //console.log(ok);
    },

});
