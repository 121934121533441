import Vue from "vue";
import Vuex from "vuex";

import SharedModule from './shared';
import { SharedState } from './shared/state';
import AuthModule from './auth';
import { AuthState } from './auth/state';

export interface StateInterface {
  Shared: SharedState,
  Auth: AuthState,
}

Vue.use(Vuex);

export default new Vuex.Store<StateInterface>({
  modules: {
    Shared: SharedModule,
    Auth: AuthModule
  }
});
