import { computed } from 'vue';
import store from "@/store";

export const useAuth =  () => {

   /* const createUser = async( user: any ) => {
        const resp = await store.dispatch('auth/createUser', user )
        return resp
    }

    const loginUser = async( user: any ) => {
        const resp = await store.dispatch('auth/signInUser', user )
        return resp
    }

    onMounted(async () =>{
        const resp = await store.dispatch('auth/checkAuthentication')
        return resp
    }
    
    const logout = () => {
        store.commit('auth/logout')
    }*/
    
    return {
        checkAuthStatus : async () =>  store.dispatch('Auth/checkAuthentication'),
        //createUser,
        loginUser : async () =>  store.dispatch('Auth/signInUser'),
        logout: () => store.commit('Auth/logout'),

        authStatus: computed(()=> store.getters['Auth/currentState']),
        username: computed(()=> store.getters['Auth/username'])
    }
}

export default useAuth