import { MutationTree } from 'vuex';
import { AuthState } from './state';
import { DataUser,ActionUser } from '../../interfaces/authInterfaces';




const mutation: MutationTree<AuthState> = {

    authUser( state,{user,idToken,actionsU} ) {
        
        if (idToken) {
            localStorage.setItem('idToken', idToken)
            localStorage.setItem('userLogin', user)
            localStorage.setItem('actionsUser', actionsU)
            state.idToken = idToken
        }
        /*if (refreshToken) {
            localStorage.setItem('refreshToken', refreshToken)
            state.refreshToken = refreshToken
        }*/
        
        state.actionsUser = JSON.parse(actionsU) as ActionUser[];
        state.user = JSON.parse(user) as DataUser;
        
        state.status = 'authenticated'
        
    },
    
    logout(state) {

        state.user = null
        state.idToken = null
       // state.refreshToken = null
        state.status = 'not-authenticated'

        localStorage.removeItem('idToken');
        localStorage.removeItem('userLogin');
        localStorage.removeItem('actionsUser');
        //localStorage.removeItem('refreshToken')
    },
    loginFailure(state) {
        state.status = 'not-authenticated';
        state.user = null;
    },
    loginUserTest(state) {

        localStorage.setItem('idToken', '12345');
        state.idToken = '12345';

        //state.status = 'authenticated'
    },
}


export default mutation;