
import { read, utils, writeFileXLSX } from "xlsx";
import store from "@/store";
import { ActionUser } from "@/interfaces/authInterfaces";

   //store.state.Auth.user as DataUser

export const DEFAULT_TIMEZONE = 'America/Costa_Rica';

export function getCurrentTime(): Date {
    const now = new Date();
    const tzOffset = now.getTimezoneOffset() * 60000; // convertir minutos a milisegundos
    const tzDiff = new Date(now.toLocaleString('en-US', { timeZone: DEFAULT_TIMEZONE })).getTime() - now.getTime(); // obtener la diferencia de tiempo con la zona horaria por defecto
    return new Date(now.getTime() + tzDiff);
}

export function getDateTimeText(date: string): string {
  const now = new Date(date);
  const tzOffset = now.getTimezoneOffset() * 60000; // convertir minutos a milisegundos
  const tzDiff = new Date(now.toLocaleString('en-US', { timeZone: DEFAULT_TIMEZONE })).getTime() - now.getTime(); // obtener la diferencia de tiempo con la zona horaria por defecto
  return new Date(now.getTime() + tzDiff).toLocaleString();
}

export function getDateUTCfromInputDate(date: string): Date {
  const fechaLocal = new Date(date);
  const fechaUTC = new Date(fechaLocal.getTime() + fechaLocal.getTimezoneOffset() * 60000);
  return fechaUTC
}

export function formatISOString(str: any) {
  if (!isISOString(str)) {
    const [date, time] = str.split(" ");
    // reformat string into YYYY-MM-DDTHH:mm:ss.sssZ
    str = `${date}T${time}.000Z`
  }
  return str;
} 

export function isISOString(date: any) {
  const formatISO = /^(\d{4})-0?(\d+)-0?(\d+)[T]0?(\d+):0?(\d+):0?(\d+)$/;
  return formatISO.test(date);
}

export const checkActionsU = (check: string) => {
  var userActionsA = store.state.Auth.actionsUser as ActionUser[];
  return userActionsA.find((i: { constantName: string; }) => i.constantName === check) != undefined;
}

export const excelToJson =async (file: File, sheetName: string) => {
    var jsonr;
  var p =  new Promise(function (resolve) {
    var reader = new FileReader();
    reader.onload = async function (e) {
      if (e.target != null) {
        var data = new Uint8Array(e.target.result as ArrayBuffer);
        var workbook = read(data, { type: "array", cellDates: true });
        //let sheetName = workbook.SheetNames[0] set default yovoy
        let worksheet = workbook.Sheets[sheetName];
        //console.log(utils.sheet_to_json(worksheet));
        await resolve(utils.sheet_to_json(worksheet));
      }
    };
    reader.readAsArrayBuffer(file);
  });
   await p.then(function async (json) {
    jsonr = json
  });
  return jsonr;
};

export const excelDateToDate =  (date:number)  => {
  var utc_days  = Math.floor(date - 25569);
   var utc_value = utc_days * 86400;                                        
   var date_info = new Date(utc_value * 1000);

   var fractional_day = date - Math.floor(date) + 0.0000001;

   var total_seconds = Math.floor(86400 * fractional_day);

   var seconds = total_seconds % 60;

   total_seconds -= seconds;

   return date_info.getFullYear() +"-"+ date_info.getMonth() +"-"+ date_info.getDate();
}

export const fileToBase64 = async (file:any) => {
  var fileBase64;
  var p = new Promise(function(resolve){
    var reader = new FileReader();
    reader.readAsDataURL(file)
    reader.onload = async function(){
      let data = [ ];
      let base64 = reader.result;
      if(base64 != null){
        data = base64.toString().split(",");
        await resolve(data[1])
      }
    }
  })
  await p.then(function async(file64){
    fileBase64 = file64
  })
  return fileBase64
}
export const padTo2Digits = (num: any) => {
  return num.toString().padStart(2, '0');
};

export const formatDateForInput = (date: any) => {
  //var dateFormatted :any = date.toLocaleDateString('sv-SE',{timeZone: "America/Costa_Rica"})
  
  if(date != null){
    let newDate = new Date(date);
    let [dateFormatted] = newDate.toISOString().split('T')
    /*
    var day = ("0" + dateLocal.getDate()).slice(-2);
    var month = ("0" + (dateLocal.getMonth() + 1)).slice(-2);
  
    var dateFormatted = dateLocal.getFullYear() + "-" + (month) + "-" + (day);*/
  
    if(newDate.getFullYear() == 1969 || newDate.getFullYear() == 1900){
      return '';
    } else  {
      return dateFormatted;
    }
  } else{
    return '';
  }
  
  
};


export const exportJsonToExcel = (json:any,nameFile:string) =>{
  const ws = utils.json_to_sheet(json);
  const wb = utils.book_new();
  utils.book_append_sheet(wb, ws, "Data");
  writeFileXLSX(wb, nameFile+".xlsx");
}

export const todayymd = (): string => {
  const date = new Date();
  const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
}

export const formatHourToUTC = (hour:Date) => {
  return new Date(Date.UTC(hour.getFullYear(),hour.getMonth(),hour.getDate(),hour.getHours(),hour.getMinutes(),hour.getSeconds()));
}