import store from '@/store';


const isAuthenticatedGuard = async( _to: any, _from: any, _next: any ) => {

    const { ok } = await store.dispatch('Auth/checkAuthentication')

    if ( ok ) _next()
    else _next({ name: 'login' })
}



export default isAuthenticatedGuard