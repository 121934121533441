
import Vue from "vue";

import { mapMutations } from "vuex";
import { checkActionsU } from "@/utils/shared/shared";

export default Vue.extend({
	name: "MenuAppBar",
	data: () => ({
		title: "PMS",
		open: [],
		optionsMenu: [

		],
		optMap: [
			["Inicio", "mdi-view-dashboard", "/", "main", []],
			["Entidades", "mdi-cards", "", "entityOpts", [
				["Usuarios", "mdi-account-tie", "users"],
				["Compañías", "mdi-office-building", "companies"],
				["Proveedores", "mdi-account-multiple-outline", "providers"],
				["Pasajeros", "mdi-account-multiple-outline", "passengers"],
				["Lista Ubicaciones D2D", "mdi-map-marker-radius-outline", "listLocationServicesD2D"]]],
			["Módulo Contable", "mdi-calculator-variant", "", "factOpts", [
				["Plantillas", "mdi-table", "template"],
				["Ajuste a Proveedor", "mdi-finance", "settingsProvider"],
				["Notas y Bonos", "mdi-note-edit", "notesToProviders"]]],
			["Módulo Proveedores", "mdi-receipt-text", "", "supplierOpts", [
				[((checkActionsU("CreatedFacP")) ? "Carga de facturas" : "Facturación de Proveedor"), "mdi-table", "payProvider"]]],
			["Módulo YOVOY", "mdi-truck-delivery", "", "paqOpts", [
				["Cargar orden", "mdi-package-variant-closed-plus", "loadOrder"],
				["Lotes", "mdi-package-check", "orderGroup"],
				["Ordenes", "mdi-package-variant-closed", "orders"],
				["Consolidados", "mdi-clipboard-check-multiple-outline", "orderbylocation"],
				["Consolidar Paquetes", "mdi-archive-edit-outline", "consolidateOrders"],
				["Completar Paquetes", "mdi-archive-check-outline", "completeOrder"]]],
			["Reportes", "mdi-receipt-text", "", "reportOpts", [
				["Fac. Cobro Cliente", "mdi-table", "reportByCompany"],
				["Fac. Cobro Cliente VIP", "mdi-table", "reportByCompanyVIP"],
				["Fac. Pago Proveedor", "mdi-table", "provBillReport"],
				["Servicios de Proveedor", "mdi-table", "serviceBySupplier"],
				["Reporte de Uso", "mdi-table", "useReport"],
				[((checkActionsU("CreatedFacP")) ? "Carga de facturas" : "Facturación de Proveedor"), "mdi-table", "payProvider"],
				["Reporte de Feedback", "mdi-table", "feedbackReport"]]],
			["Configuración", "mdi-cog", "", "configOpts", [
				["Roles", "mdi-account-tag-outline", "roles"],
				["Permisos", "mdi-account-lock-open-outline", "actions"],
				["Puestos", "mdi-badge-account-horizontal", "positions"]]],
			["Módulo VIP", "mdi-taxi", "", "VIPOpts", [
				["Cotizaciones", "mdi-calculator", "VIPQuotationsList"],
				["Descuentos", "mdi-percent", "VIPDiscount"],
				["Servicios", "mdi-ticket-confirmation", "VIPServices"]
			]],
			["Módulo de Monitoreo", "mdi-human-greeting-proximity", "", "monitorOpts", [
				["Monitoreo de Rutas", "mdi-monitor-dashboard", "routesMonitor"],
			]],
			["Módulo Softland", "mdi-card-bulleted-settings", "", "softlandOpts", [
				["Proveedores", "mdi-account-multiple-outline", "providerInvoice"],
				["Compañías", "mdi-office-building", "companyInvoice"],
				["Pedidos a facturas", "mdi-card-bulleted-settings-outline", "ordersInvoices"],
				["Facturas", "mdi-receipt-text", "invoices"],
				["Servicios eliminados", "mdi-package-variant-closed-remove", "servicesDeleted"],
			]],
			["Tutoriales", "mdi-youtube", "/tutorials", "tutorials", []],
			["Módulo Tiquetes", "mdi-ticket-outline", "", "ticketsOpts", [
				["Mis Tickets", "mdi-ticket-account", "tickets"],
				["Motivos", "mdi-cog", "reasons"]
			]],
			["Formularios", "mdi-file-document-multiple", "", "formsOpts", [
				["Evento TELLEVO", "mdi-file-document", "redirectEventTeLlevo"],
				["Empleado TELLEVO", "mdi-file-document", "formEmployeeTeLlevo"],
				["Inspección Vehículo", "mdi-file-document", "formVehicleInspection"],
				["Ubicación Servicios D2D", "mdi-file-document", "formLocationServicesD2D"],
			]],
		],
		// optMap2: [
		// 	["Inicio", "mdi-view-dashboard", "/", "main", []],
		// 	["Facturación", "mdi-receipt-text", "", "factOpts", [["Plantillas", "mdi-table", "template"],
		// 		["Ajuste a Proveedor", "mdi-table", "settingsProvider"],
		// 		["Notas y Bonos", "mdi-table", "notesToProviders"],
		// 		["Fac. Cobro Cliente", "mdi-table", "reportByCompany"],
		// 		["Fac. Pago Proveedor", "mdi-table", "provBillReport"],
		// 		[((checkActionsU("CreatedFacP")) ? "Carga de facturas" : "Facturación de Proveedor"), "mdi-table", "payProvider"]]],
		// 	["Entidades", "mdi-account-multiple-outline", "", "entityOpts", [
		// 		["Roles", "mdi-account-tag-outline", "roles"],
		// 		["Compañías", "mdi-office-building", "companies"],
		// 		["Proveedores", "mdi-account-multiple-outline", "providers"]]],
		// 	["Paquetería", "mdi-truck-delivery", "", "paqOpts", [
		// 		["Cargar orden", "mdi-package-variant-closed-plus", "loadOrder"],
		// 		["Lotes", "mdi-package-check", "loadFormOrder"],
		// 		["Ordenes", "mdi-package-variant-closed", "loadFormOrder"],
		// 		["Consolidados", "mdi-clipboard-check-multiple-outline", "loadFormOrder"]]],
		// 	["Tutoriales", "mdi-youtube", "/tutorials", "tutorials", []]
		// ],
		mini: false,
	}),
	methods: {

		...mapMutations('Shared', ['stateDrawer'])
	},
	computed: {
		getDrawerState: {
			get() {
				return this.$store.getters['Shared/isDrawerS']
			},
			set(newDrawer) {
				return newDrawer
			}
		},

	}, beforeMount() {
		var optsChild: any = [];
		this.$data.optMap.forEach((items: any) => {
			if (checkActionsU(items[3])) {
				console.log("------", items[3]);
				optsChild = [];
				items[4].forEach((itemChild: any) => {
					if (checkActionsU(itemChild[2])) {
						optsChild.push(itemChild);
					}
				});
				this.$data.optionsMenu.push([items[0], items[1], items[2], items[3], optsChild]);
			}
		});
	}
});
