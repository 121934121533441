import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
//import { RouteConfigMultipleViews } from "vue-router/types/router";
import isAuthenticatedGuard from "../router/auth-guard";

Vue.use(VueRouter);

const authLoginRouter = {
  component: () => import("../layouts/AuthLayout.vue"),
  children: [
    {
      path: "",
      name: "login",
      component: () => import("../views/authViews/LoginView.vue"),
    },
    {
      path: "/resetpassword",
      name: "resetpassword",
      component: () => import("../views/authViews/ResetPassword.vue"),
    },
    {
      path: "/rp",
      name: "resetpasswordsms",
      component: () => import("../views/authViews/ResetPasswordSms.vue"),
    },
    {
      path: "/reportByCompanyPublic",
      name: "reportByCompanyPublic",
      component: () => import("../views/billingViews/BillingByCompany.vue"),
    },
    {
      path: "/trackOrder",
      name: "trackOrder",
      component: () => import("../views/ordersViews/TrackOrderClientView.vue"),
    },
    {
      path: "/accepted",
      name: "accepted",
      component: () => import("../views/shared/AcceptedView.vue"),
    },
    {
      path: "/denied",
      name: "denied",
      component: () => import("../views/shared/DeniedView.vue"),
    },
    {
      path: "/trackVIP",
      name: "trackVIP",
      component: () => import("../views/vipViews/TrackVIP.vue"),
    },
    {
      path: "/replied",
      name: "replied",
      component: () => import("../views/shared/RepliedView.vue"),
      props: true,
    },
    {
      path: "/notfoundType",
      name: "notfoundtype",
      component: () => import("../views/shared/NotFoundView.vue"),
      props: true,
    },
    {
      path: "/newEmployeeTellevo",
      name: "newEmployeeTellevo",
      component: () => import("../views/formViews/InfoEmployeeView.vue"),
      props: true,
    },
    {
      path: "/insurancePolicy",
      name: "insurancePolicy",
      component: () => import("../views/shared/InsurancePolicyView.vue"),
      props: true,
    },
    {
      path: "/confirmOrderYovoy",
      name: "confirmOrderYovoy",
      component: () => import("../views/formViews/ConfirmFormYoVoyView.vue"), 
      props: true,
    },
  ],
};

const homeRouter = {
  component: () => import("../layouts/HomeLayout.vue"),
  children: [
    {
      path: "",
      name: "home",
      component: () => import("../views/HomeView.vue"),
    },
    {
      path: "/templatefac",
      name: "templateFac",
      component: () => import("../views/billingViews/FactView.vue"),
      props: true,
    },
    {
      path: "/template",
      name: "template",
      component: () => import("../views/billingViews/TemplateView.vue"),
    },
    {
      path: "/settingsProvider",
      name: "settingsProvider",
      component: () => import("../views/billingViews/SettingProviderView.vue"),
    },
    {
      path: "/loadOrder",
      name: "loadOrder",
      component: () => import("../views/ordersViews/LoadOrderView.vue"),
    },
    {
      path: "/orderGroup",
      name: "orderGroup",
      component: () => import("../views/ordersViews/OrderGroupView.vue"),
    },
    {
      path: "/orders",
      name: "orders",
      component: () => import("../views/ordersViews/OrdersView.vue"),
    },
    {
      path: "/payProvider",
      name: "payProvider",
      component: () => import("../views/billingViews/ProviderBillingView.vue"),
    },
    {
      path: "/useReport",
      name: "useReport",
      component: () => import("../views/reportViews/UseReportView.vue"),
    },
    {
      path: "/billingProvider",
      name: "billingProvider",
      component: () => import("../views/billingViews/BillingFormView.vue"),
    },
    {
      path: "/billDetails",
      name: "billDetails",
      component: () => import("../views/billingViews/BillDetailsView.vue"),
      props: true,
    },
    {
      path: "/billDetailsProv",
      name: "billDetailsProv",
      component: () => import("../views/billingViews/BillDetailsProvView.vue"),
      props: true,
    },
    {
      path: "/providers",
      name: "providers",
      component: () =>
        import("../views/userViews/providers/ListProviderView.vue"),
    },
    {
      path: "/companies",
      name: "companies",
      component: () => import("../views/companiesViews/ListCompaniesView.vue"),
    },
    {
      path: "/notesToProviders",
      name: "notesToProviders",
      component: () => import("../views/billingViews/NotesToProviders.vue"),
    },
    {
      path: "/reportByCompany",
      name: "reportByCompany",
      component: () => import("../views/billingViews/BillingByCompanies.vue"),
    },
    {
      path: "/reportByCompanyVIP",
      name: "reportByCompanyVIP",
      component: () =>
        import("../views/billingViews/BillingByCompaniesVIP.vue"),
    },
    {
      path: "/provBillReport",
      name: "provBillReport",
      component: () => import("../views/billingViews/ProvBillReportView.vue"),
      query: { startDate: new Date(), endDate: new Date() },
    },
    {
      path: "/tutorials",
      name: "tutorials",
      component: () => import("../views/tutorialsViews/TutorialsView.vue"),
    },
    {
      path: "/roles",
      name: "roles",
      component: () => import("../views/userViews/roles/RolesView.vue"),
    },
    {
      path: "/VIPQuotation",
      name: "VIPQuotation",
      component: () => import("../views/vipViews/VIPQuotation.vue"),
      props: true,
    },
    {
      path: "/VIPQuotationsList",
      name: "VIPQuotationsList",
      component: () => import("../views/vipViews/VIPQuotationList.vue"),
    },
    {
      path: "/serviceBySupplier",
      name: "serviceBySupplier",
      component: () => import("../views/billingViews/ServiceBySuppliers.vue"),
    },
    {
      path: "/actions",
      name: "actions",
      component: () => import("../views/userViews/roles/ActionsView.vue"),
    },
    {
      path: "/VIPDiscount",
      name: "VIPDiscount",
      component: () => import("../views/vipViews/VIPDiscountView.vue"),
    },
    {
      path: "/VIPServices",
      name: "VIPServices",
      component: () => import("../views/vipViews/VIPServicesView.vue"),
    },
    {
      path: "/reasons",
      name: "reasons",
      component: () => import("../views/ticketsViews/ReasonsView.vue"),
    },
    {
      path: "/users",
      name: "users",
      component: () => import("../views/userViews/UsersView.vue"),
    },
    {
      path: "/tickets",
      name: "tickets",
      component: () => import("../views/ticketsViews/TicketsView.vue"),
    },
    {
      path: "/positions",
      name: "positions",
      component: () => import("../views/userViews/PositionsView.vue"),
    },
    {
      path: "/consolidateOrders",
      name: "consolidateOrders",
      component: () => import("../views/ordersViews/ConsolidateOrdersView.vue"),
    },
    {
      path: "/completeOrder",
      name: "completeOrder",
      component: () => import("../views/ordersViews/CompleteOrderView.vue"),
    },
    {
      path: "/feedbackReport",
      name: "feedbackReport",
      component: () => import("../views/reportViews/FeedbackReport.vue"),
    },
    {
      path: "/passengers",
      name: "passengers",
      component: () => import("../views/userViews/PassengersView.vue"),
    },
    {
      path: "/formVehicleInspection",
      name: "formVehicleInspection",
      component: () =>
        import("../views/formViews/FormVehicleInspectionView.vue"),
    },
    {
      path: "/formEmployeeTeLlevo",
      name: "formEmployeeTeLlevo",
      component: () => import("../views/formViews/InfoEmployeeView.vue"),
    },
    {
      path: "/redirectEventTeLlevo",
      name: "redirectEventTeLlevo",
      component: () => import("../views/formViews/FormEventTeLlevoView.vue"),
    },
    {
      path: "/formLocationServicesD2D",
      name: "formLocationServicesD2D",
      component: () =>
        import("../views/formViews/FormLocationServicesD2DView.vue"),
      props: true,
    },
    {
      path: "/listLocationServicesD2D",
      name: "listLocationServicesD2D",
      component: () => import("../views/userViews/LocationServicesD2DView.vue"),
      props: true,
    },
    {
      path: "/routesMonitor",
      name: "routesMonitor",
      component: () =>
        import("../views/monitoringRoutesViews/RoutesMonitorView.vue"),
      props: true,
    },
    {
      path: "/routeDetails",
      name: "routeDetails",
      component: () =>
        import("../views/monitoringRoutesViews/RouteDetailsView.vue"),
      props: true,
    },
    {
      path: "/changeDriver",
      name: "changeDriver",
      component: () =>
        import("../views/monitoringRoutesViews/ChangeDriverRouterView.vue"),
      props: true,
    },
    {
      path: "/createOrder",
      name: "createOrder",
      component: () =>
        import("../views/softlandViews/CreateOrderSoftlandView.vue"),
      props: true,
    },
    {
      path: "/ordersInvoices",
      name: "ordersInvoices",
      component: () => import("../views/softlandViews/OrderSoftlandView.vue"),
      props: true,
    },
    {
      path: "/invoices",
      name: "invoices",
      component: () =>
        import("../views/softlandViews/InvoicesSoftlandView.vue"),
      props: true,
    },
    {
      path: "/servicesDeleted",
      name: "servicesDeleted",
      component: () => import("../views/softlandViews/ServicesDeletedView.vue"),
      props: true,
    },
    {
      path: "/providerInvoice",
      name: "providerInvoice",
      component: () =>
        import("../views/softlandViews/ProvidersInvoiceView.vue"),
      props: true,
    },
    {
      path: "/companyInvoice",
      name: "companyInvoice",
      component: () =>
        import("../views/softlandViews/CompaniesInvoiceView.vue"),
      props: true,
    },
  ],
};

const routes: Array<RouteConfig> = [
  {
    path: "/login",
    ...authLoginRouter,
  },
  {
    path: "/",
    beforeEnter: isAuthenticatedGuard,
    ...homeRouter,
  },
  /*{
    path: "/:pathMatch(.*)*",
    name: "notFound",
    component: () => import("../views/shared/NotFoundView.vue"),
  },*/
];

const router = new VueRouter({
  routes,
});

export default router;
