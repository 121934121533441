import { ActionTree, Commit } from "vuex";
import { AuthState } from "./state";
import { StateInterface } from "../index";

const actions: ActionTree<AuthState, StateInterface> = {
  async signInUser({ commit }) {
    try {
      return { ok: true };
    } catch (error: any) {
      return { ok: false, message: "error" };
    }
  },

  /* async signInUser  ({ commit }, user )  {
 
         const { email, password } = user
     
         try {
             
             const { data } = await authApi.post(':signInWithPassword', { email, password, returnSecureToken: true })
             const { displayName, idToken, refreshToken } = data
             
             user.name = displayName
     
             commit('loginUser', { user, idToken, refreshToken })
     
             return { ok: true }
     
         } catch (error) {
             return { ok: false, message: error.response.data.error.message }
         }
     
     }*/

  async checkAuthentication({ commit }) {
    const idToken = localStorage.getItem("idToken");
    const user = localStorage.getItem("userLogin");
    const actionsU = localStorage.getItem("actionsUser");
    // console.log("actions");
    // console.log(actionsU);
    // console.log(user);
    //const refreshToken = localStorage.getItem('refreshToken')
    // console.log(user);
    if (!idToken) {
      commit("logout");
      return { ok: false, message: "No hay token" };
    } else {
      commit("authUser", { user: user, idToken: idToken, actionsU });
    }

    /*try {

            const data: any = await getUserLogin()
            // console.log(data)
            const { displayName, email } = data.users[0]

            const user = {
                name: displayName,
                email
            }

            commit('loginUser', { user, idToken, refreshToken })

            return { ok: true }

        } catch (error: any) {
            commit('logout')
            return { ok: false, message: error.response.data.error.message }
        }*/

    //commit('loginUserTest')

    return { ok: true };
  },
};

export default actions;
